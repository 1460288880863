import { createSlice, PayloadAction, Reducer } from "@reduxjs/toolkit";
import { GetStoreByIdResponse } from "@switcherstudio/switcher-api-client";
import { getShopInfo } from "./thunks";
import { ShopState } from "./types";

const initialState: ShopState = {
    shopInfo: undefined,
}

export const shop = createSlice({
    name: "shop",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getShopInfo.fulfilled, (state, { payload }: PayloadAction<GetStoreByIdResponse>) => {
            state.shopInfo = payload;
        });

        builder.addCase(getShopInfo.rejected, (_, { error }) => {
            throw error;
        });
    }
});

export default shop.reducer as Reducer<ShopState>;