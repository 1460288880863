import { Card, Layout, Link, Stack } from "@shopify/polaris";
import React from "react";

export const HelpSectionCard: React.FC = () => (
  <Layout.AnnotatedSection
    title="Help Center"
    description="Learn how to use Cartr to sell your Shopify products and how to use Switcher to create your streams. Start with these featured articles and find many more in our Help Center."
  >
    <Card
      sectioned
      title="Featured Articles"
      actions={[
        {
          content: "Visit Help Center",
          url: import.meta.env.VITE_HELP_CENTER_LINK,
          external: true,
        },
      ]}
    >
      <Stack vertical>
        <Link
          url="https://support.switcherstudio.com/en/articles/5967515-getting-started-with-cartr"
          external
        >
          Getting Started With Cartr
        </Link>
        <Link
          url="https://support.switcherstudio.com/en/articles/5971754-using-cartr-in-a-livestream"
          external
        >
          Using Cartr in a Livestream
        </Link>
        <Link
          url="https://support.switcherstudio.com/en/collections/3336092-new-shopify-switcher-studio"
          external
        >
          NEW: Shopify & Switcher Studio
        </Link>
      </Stack>
    </Card>
  </Layout.AnnotatedSection>
);
