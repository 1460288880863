import { createApp } from "@shopify/app-bridge";
import { Redirect } from "@shopify/app-bridge/actions";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { AppDispatch } from "store/store";
import { logout } from "store/user/slice";
import {
  connectShopToUser,
  deleteConnectedShopUser,
  exchangeCode,
  getUserInfo,
} from "store/user/thunks";
import {
  getShopInfo,
} from "store/shop/thunks";
import { UserState } from "store/user/types";
import { ShopState } from "store/shop/types";

interface IPostMessageEventData {
  type: string;
  payload: any;
}

export const useAccountConnection = (
  shop: string,
  host: string,
  isTopFrame: boolean,
  urlParams: URLSearchParams,
  userState: UserState,
  shopState: ShopState,
) => {
  const dispatch = useDispatch<AppDispatch>();
  const rollbarInstance = useSelector((s: RootState) => s.rollbar.instance);

  const [connected, setConnected] = useState<boolean>();
  const [accountWindow, setAccountWindow] = useState(null);
  const [
    shouldShowAccountConnectionSuccessBanner,
    setShouldShowAccountConnectionSuccessBanner,
  ] = useState<boolean>(false);
  const [
    shouldShowAccountConnectionErrorBanner,
    setShouldShowAccountConnectionErrorBanner,
  ] = useState<boolean>(false);

  const connectSwitcherAccount = useCallback(
    (showLoginPage: boolean = true) => {
      const loginUrl = new URL(
        `${import.meta.env.VITE_ACCOUNT_PROJECT_URL}/${
          showLoginPage ? "login" : "create-account"
        }`
      );
      loginUrl.searchParams.append(
        "clientId",
        encodeURIComponent(import.meta.env.VITE_CLIENT_ID)
      );
      loginUrl.searchParams.append("showLoginBtn", "true");
      loginUrl.searchParams.append("subscribe", "false");

      //https://stackoverflow.com/a/32261263
      const popupWindow = (url, windowName, win, w, h) => {
        const y = win.outerHeight / 2 + win.screenY - h / 2;
        const x = win.outerWidth / 2 + win.screenX - w / 2;
        return win.open(
          url,
          windowName,
          `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, popup, width=${w}, height=${h}, top=${y}, left=${x}`
        );
      };

      setAccountWindow(
        popupWindow(
          loginUrl.toString(),
          "Switcher Studio Accounts",
          window,
          480,
          620
        )
      );
    },
    [setAccountWindow]
  );

  const disconnectSwitcherAccount = () => {
    setConnected(false);
    dispatch(logout());
    dispatch(deleteConnectedShopUser(shop));
  };

  const exchangeAuthCode = useCallback(
    async (code) => {
      if (code && !userState?.ticket) {
        await dispatch(exchangeCode(code));
        await dispatch(getUserInfo());
        try {
          await dispatch(getShopInfo(shop));
          setShouldShowAccountConnectionSuccessBanner(true);
        } catch (e) {
          setShouldShowAccountConnectionErrorBanner(true);
        }
      }
    },
    [dispatch, shop, userState.ticket]
  );

  const requestShopifyAccessToken = useCallback(() => {
    const permissionUrl = new URL(`${import.meta.env.VITE_API_URL}/api/ShopRedirects/RedirectForAuthorization?${urlParams}`)
    // If the current window is the 'parent', change the URL by setting location.href

    if (isTopFrame) {
      window.location.assign(permissionUrl.toString());
    } else {
      const app = createApp({
        apiKey: import.meta.env.VITE_SHOPIFY_CLIENTID,
        host: host
      });
      const redirect = Redirect.create(app);
      redirect.dispatch(Redirect.Action.REMOTE, permissionUrl.toString());
    }
  }, [urlParams, isTopFrame, host]);


  useEffect(() => {
    if (isTopFrame && import.meta.env.VITE_ENV !== 'local') {
      requestShopifyAccessToken();
    }
  })

  useEffect(() => {
    const connectShop = async () => {
      if (
        !shopState?.shopInfo?.connectedShopUser &&
        userState.ticket != null
      ) {
        try {
          await dispatch(connectShopToUser(shop));
          dispatch(getShopInfo(shop));
        } catch (e) {
          rollbarInstance.warn(
            `Shop ${shop} does not have any available access tokens in the database.`
          );
          requestShopifyAccessToken();
        }
      }
    };

    connectShop();
  }, [
    dispatch,
    requestShopifyAccessToken,
    shopState,
    userState.ticket,
    rollbarInstance,
    shop,
    exchangeAuthCode,
  ]);

  useEffect(() => {
    window.addEventListener("message", (event) => {
      if (
        event.origin === import.meta.env.VITE_ACCOUNT_PROJECT_URL &&
        event.data[0] !== "!" &&
        typeof event.data == "string"
      ) {
        try {
          const data: IPostMessageEventData = JSON.parse(event.data);
          if (data) {
            switch (data.type) {
              case "login":
                accountWindow.close();
                exchangeAuthCode(data.payload.code);
                break;
            }
          }
        } catch (e) {
          console.warn(e);
        }
      }
    });

    dispatch(getShopInfo(shop));
  }, [dispatch, shop, exchangeAuthCode, accountWindow]);

  useEffect(() => {
    if (!shopState?.shopInfo?.connectedShopUser) {
      setConnected(false);
    } else {
      setConnected(shopState?.shopInfo?.connectedShopUser !== null);
    }
  }, [shopState]);

  return {
    connected,
    connectSwitcherAccount,
    disconnectSwitcherAccount,
    shouldShowAccountConnectionSuccessBanner,
    shouldShowAccountConnectionErrorBanner,
  };
};
