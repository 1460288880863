import React from "react";
import { Card, Spinner, Stack } from "@shopify/polaris";
import styles from "./CardStack.module.css";

export interface CardStackProps {
  cards: {
    title: string;
    content: string | number;
  }[];
  loading?: boolean;
}

export const CardStack: React.FC<CardStackProps> = ({ cards, loading }) => (
  <div className={styles["card-stack"]}>
    {loading ? <Spinner /> :
    <Stack distribution="fill">
      {cards.map((card) => (
        <Card title={card.title} sectioned>
          <h1>{card.content}</h1>
        </Card>
      ))}
    </Stack>
    }
  </div>
);
