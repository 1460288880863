import React, { useCallback, useState } from "react";
import { Button, InlineError, Stack, TextField } from "@shopify/polaris";
import { useDispatch, useSelector } from "react-redux";
import { claimCoupon } from "store/user/thunks";
import { AppDispatch } from "store/store";
import { RootState } from "store/reducers";

export const CouponInputField: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const shopState = useSelector((s: RootState) => s.shop);
  const [value, setValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const onSubmit = useCallback(async () => {
    setLoading(true);
    if (!shopState?.shopInfo?.connectedShopUser) {
      setErrorMessage("Connect account to claim coupon");
    } else {
      const { payload } = await dispatch(claimCoupon(value));
      if (!payload) {
        setErrorMessage("Invalid coupon code");
        setLoading(false);
      }
    }
  }, [dispatch, shopState?.shopInfo?.connectedShopUser, value]);

  const onClear = useCallback(
    (_: string) => {
      setValue("");
      setErrorMessage(undefined);
    },
    [setValue]
  );

  return (
    <div>
      <Stack vertical spacing="tight">
        <Stack alignment="trailing">
          <TextField
            id="coupon-input"
            label="Promo / Coupon Code"
            value={value}
            onChange={(v) => setValue(v)}
            autoComplete="off"
            clearButton
            onClearButtonClick={onClear}
            disabled={loading}
          />
          <Button onClick={onSubmit} disabled={value.length === 0 || loading}>
            Apply
          </Button>
        </Stack>
        {errorMessage && (
          <InlineError message={errorMessage} fieldID="coupon-input" />
        )}
      </Stack>
    </div>
  );
};
