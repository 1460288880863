import React, { useState } from "react";
import { subMonths } from "date-fns";
import { LivestreamDatePicker } from "./LivestreamDatePicker";
import { Card, DataTable, Stack } from "@shopify/polaris";
import { useGetLivestreams } from "hooks/useGetLivestreams";
import { SkeletonCard } from "components/cards/SkeletonCard";

export interface LivestreamsTableProps {
  shopId: string;
  locale: string;
}

export interface DateRange {
  start: Date;
  end: Date;
}

export const LivestreamsTable: React.FC<LivestreamsTableProps> = ({shopId}) => {
  const [selectedDates, setSelectedDates] = useState<DateRange>({
    start: subMonths(new Date(), 1),
    end: new Date(),
  });

  const {loading, livestreams, totals} = useGetLivestreams(shopId, selectedDates); 

  if (loading) {
    return <SkeletonCard />
  }

  return (
    <Stack vertical>
      <LivestreamDatePicker
        selectedDates={selectedDates}
        setSelectedDates={setSelectedDates}
      />
      <Card>
        <DataTable
          columnContentTypes={[
            "text",
            "text",
            "numeric",
            "numeric",
            "numeric",
          ]}
          headings={[
            "Name",
            "Date",
            "Unique Buyers",
            "Items sold",
            "Amount",
          ]}
          rows={livestreams}
          totals={totals}
        />
      </Card>
    </Stack>
  );
};
