import React, { useState } from "react";
import { AccountConnection, Link } from "@shopify/polaris";
import { AccountProps } from ".";
import { DisconnectModal } from "components/modals/DisconnectModal";

export function DisconnectAccount({ onAction, email }: AccountProps) {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  return (
    <>
      <AccountConnection
        connected
        action={{ content: "Disconnect", onAction: () => setModalIsOpen(true) }}
        accountName={email}
        title={
          <Link
            url="https://dashboard.switcherstudio.com"
            children={email}
          ></Link>
        }
        details=""
      />
      <DisconnectModal
        open={modalIsOpen}
        cancel={() => setModalIsOpen(false)}
        onAction={onAction}
      />
    </>
  );
}
