const nfMap = new Map();
export const format = (currencyCode: string, amount: number, locale: string = "en-US"): string => {
    if (!nfMap.has(currencyCode)) {
        nfMap.set( currencyCode, new Intl.NumberFormat(locale, {
                style: "currency",
                currency: currencyCode,
                minimumFractionDigits: 2, 
                maximumFractionDigits: 2,
            })
        );
    }
    const nf = nfMap.get(currencyCode)
    return nf.format(amount);
}