import React from "react";
import ReactDOM from "react-dom";
import { AppProvider } from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";

import "@shopify/polaris/dist/styles.css";
import { Provider } from "react-redux";
import store from "store/store";
import { BrowserRouter } from "react-router-dom";
import { App } from "App";

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <AppProvider i18n={enTranslations}>
        <App />
      </AppProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
