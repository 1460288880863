import React from "react";
import { Card, DataTable, SortDirection, Stack } from "@shopify/polaris";
import { calculateTotals } from "helpers/tables";

export interface BroadcastProduct {
  Id: string;
  Name: string;
  Upi: string;
  ItemsSold: number;
  Amount: string;
}

export interface ProductsSoldTableProps {
  transactions: BroadcastProduct[];
  handleSortProductsSold: (headingIndex: number, direction: SortDirection) => void;
}

const parseProductsSoldRows = (products: BroadcastProduct[]) => {
  return products.map((product) => [
    product.Name,
    product.Upi,
    product.ItemsSold,
    product.Amount,
  ]);
};

export const ProductsSoldTable: React.FC<ProductsSoldTableProps> = ({
  transactions,
  handleSortProductsSold
}) => {
  return (
    <Stack vertical>
      <Card>
        <DataTable
          columnContentTypes={["text", "text", "numeric", "numeric"]}
          headings={["Name", "UPI", "Items sold", "Amount"]}
          sortable={[true, false, true, true]}
          rows={parseProductsSoldRows(transactions)}
          totals={calculateTotals(parseProductsSoldRows(transactions))}
          onSort={handleSortProductsSold}
        />
      </Card>
    </Stack>
  );
};
