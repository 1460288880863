import { client } from "api/client";
import { useCallback, useEffect, useState } from "react";

export const useShopQuery = <T = any>(query: string, variables?: any, auto: boolean = true) => {
  const [data, setData] = useState<T>();
  const [error, setError] = useState();

  const callback = useCallback(async () => {
    let response: any;

    try {
      response = await client.shopify_QueryShopifyStorefront({
        Query: query,
        Variables: variables
      })

      setData(response);
    } catch (e: any) {
      setError(e.message)
    }
  }, [query, variables])

  useEffect(() => {
    if (auto) {
      callback();
    }
  }, [callback, auto])

  return {data, error, callback, loading: !data && !error}
}
