import React from "react";
import {
  Card,
  SkeletonBodyText,
  SkeletonDisplayText,
  TextContainer,
} from "@shopify/polaris";

export const SkeletonPlanCard = () => (
  <>
    <Card.Subsection>
      <TextContainer>
        <SkeletonDisplayText size="small" />
        <SkeletonBodyText />
      </TextContainer>
    </Card.Subsection>
    <Card.Subsection>
      <TextContainer>
        <SkeletonDisplayText size="small" />
        <SkeletonBodyText />
      </TextContainer>
    </Card.Subsection>
  </>
);
