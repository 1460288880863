import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { getUserClaimedCoupon } from "store/user/thunks";

export const useClaimedCoupon = () => {
  const dispatch = useDispatch();
  const { claimedCoupon } = useSelector((s: RootState) => s.user);

  useEffect(() => {
    if (!claimedCoupon) {
      dispatch(getUserClaimedCoupon())
    }
  }, [dispatch, claimedCoupon])

  return claimedCoupon;
}