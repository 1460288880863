import React, { useCallback } from "react";
import { Button, Heading, Layout, Page } from "@shopify/polaris";
import { useGetLivestream } from "hooks/useGetLivestream";
import { useParams } from "react-router-dom";
import store from "store/store";
import { createApp } from "@shopify/app-bridge";
import { Redirect } from "@shopify/app-bridge/actions";
import { CardStack } from "components/stacks/CardStack";
import { ProductsSoldTable } from "./ProductsSoldTable";

export const LivestreamDetailPage: React.FC = () => {
  const { id } = useParams();
  const { livestream, handleSortProductsSold } = useGetLivestream(id);

  const state = store.getState();

  const toAnalyticsPage = useCallback(() => {
    const app = createApp({
      apiKey: import.meta.env.VITE_SHOPIFY_CLIENTID,
      host: state.navigation.host,
    });
    const redirect = Redirect.create(app);
    redirect.dispatch(Redirect.Action.APP, `/analytics`);
  }, [state.navigation.host]);

  return (
    <Page
      breadcrumbs={[
        { content: "Analytics", onAction: () => toAnalyticsPage() },
      ]}
      title={livestream.Name}
      subtitle={livestream.Date}
      primaryAction={
        <Button
          monochrome
          connectedDisclosure={{
            accessibilityLabel: "Other actions",
            actions: [
              {
                content: "Another action",
                accessibilityLabel: "Another action",
                onAction: () => console.log("Another action"),
              },
            ],
          }}
        >
          More Actions
        </Button>
      }
      secondaryActions={[
        {
          content: "Print",
          accessibilityLabel: "Print analytics",
          onAction: () => console.log("Print"),
        },
        {
          content: "Export",
          accessibilityLabel: "Export analytics",
          onAction: () => console.log("Export"),
        },
      ]}
    >
      <Layout>
        <div style={{ width: "100%" }}>
          <Layout.Section>
            <CardStack
              cards={[
                {
                  title: "Unique Buyers",
                  content: livestream.UniqueBuyers,
                },
                {
                  title: "Items sold",
                  content: livestream.ItemsSold,
                },
                {
                  title: "Amount sold",
                  content: livestream.AmountSold,
                },
                {
                  title: "Sales fee",
                  content: livestream.SalesFee,
                },
              ]}
            />
          </Layout.Section>
        </div>

        <Layout.Section>
          <Heading>Products Sold</Heading>
          <ProductsSoldTable
            transactions={livestream.ProductsSold}
            handleSortProductsSold={handleSortProductsSold}
          />
        </Layout.Section>
      </Layout>
    </Page>
  );
};
