import React, { useState } from "react";
import { Card, Collapsible, Stack, TextContainer } from "@shopify/polaris";
import { CouponResponse, Product } from "@switcherstudio/switcher-api-client";
import { CouponInputSection } from "components/sections/CouponInputSection";
import { Plan } from "helpers/subscriptionPlans";
import Checkmark from "assets/icons/Checkmark.svg?react";
import { PlanButtonGroup } from "./PlanButtonGroup";
import { PlanState } from "./types";
import styles from "./index.module.css";

export interface ShopifyPlanCardProps {
  shopifyPlans: Plan[];
  currentUserProduct: Product;
  planState: PlanState;
  claimedCoupon: CouponResponse;
}

export const ShopifyPlanCard: React.FC<ShopifyPlanCardProps> = ({
  planState,
  claimedCoupon,
  shopifyPlans,
  currentUserProduct,
}) => {
  const [expandedPlans, setExpandedPlans] = useState<Plan["name"][]>([]);

  const filteredShopifyPlans = shopifyPlans?.length
    ? shopifyPlans.filter((plan) =>
        plan.prices.some((price) => !!price.IsPublic && !!price.Active)
      )
    : [];

  const availablePlans =
    planState === PlanState.SHOPIFY_SUBSCRIPTION
      ? shopifyPlans?.filter(
          (plan) =>
            plan.name?.toLowerCase() === currentUserProduct?.Name?.toLowerCase()
        )
      : filteredShopifyPlans;

  return (
    <>
      <CouponInputSection
        claimedCoupon={claimedCoupon}
        isSubsection
        isPrompted
      />
      {availablePlans.map((plan) => (
        <Card.Subsection key={plan.id}>
          <TextContainer>
            <h2 className="Polaris-Heading">{plan.title}</h2>
            <p>{plan.description}</p>
            <Collapsible id={plan.id} open={expandedPlans.includes(plan.name)}>
              {plan.features.map((feature, index) => (
                <div className={styles["feature-container"]} key={index}>
                  <Stack>
                    <Checkmark />
                    <p>{feature}</p>
                  </Stack>
                </div>
              ))}

              {plan.prices.some((price) => price.PlanType === 1) && (
                <>
                  {plan.prices
                    .filter(
                      (price) => price.PlanType === 1 && price.Terms?.length > 0
                    )
                    .map((price, index) => (
                      <p className={styles["usage-fee-copy"]}>{price.Terms}</p>
                    ))}
                </>
              )}
            </Collapsible>
          </TextContainer>
          <PlanButtonGroup
            plan={plan}
            activeProduct={currentUserProduct}
            planState={planState}
            onExpandFeatures={() =>
              setExpandedPlans(
                expandedPlans.includes(plan.name)
                  ? [
                      ...expandedPlans.filter(
                        (planName) => planName !== plan.name
                      ),
                    ]
                  : [...expandedPlans, plan.name]
              )
            }
            featuresExpanded={expandedPlans.includes(plan.name)}
            claimedCoupon={claimedCoupon}
          />
        </Card.Subsection>
      ))}
    </>
  );
};
