import React, { useEffect, useState } from "react";
import { Banner } from "@shopify/polaris";

export interface AccountConnectionErrorBannerProps {
  shouldBeShownByDefault: Boolean;
}

export const AccountConnectionErrorBanner: React.FC<AccountConnectionErrorBannerProps> = ({
  shouldBeShownByDefault
}) => {
  const [dismissed, setDismissed] = useState<Boolean>(!shouldBeShownByDefault);

  useEffect(() => {
    setDismissed(!shouldBeShownByDefault);
  }, [shouldBeShownByDefault])

  if (dismissed) {
    return null;
  }

  return (
    <Banner
      title="There was a problem connection your Switcher Account"
      status="critical"
      onDismiss={() => setDismissed(true)}
    >
      <p>
        Body text
      </p>
    </Banner>
  );
};
