import { createSlice, PayloadAction, Reducer } from "@reduxjs/toolkit";
import { NavigationState } from "./types";

const initialState: NavigationState = {
    host: undefined,
    shop: undefined,
    locale: undefined,
}

export const navigation = createSlice({
    name: "navigation",
    initialState,
    reducers: {
        setHost: (state, { payload }: PayloadAction<string>) => {
            state.host = payload;
        },
        setShop: (state, { payload }: PayloadAction<string>) => {
            state.shop = payload;
        },
        setLocale: (state, { payload }: PayloadAction<string>) => {
            state.locale = payload;
        }
    }
});

export const {
    setHost,
    setShop,
    setLocale
} = navigation.actions;
  
  export default navigation.reducer as Reducer<NavigationState>;