import { configureStore, Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import {
  persistReducer,
} from "redux-persist";
import rootReducer, { RootState } from "./reducers";

import storage from "redux-persist/lib/storage"; // localStorage

const persistConfig = {
  key: "shopify",
  storage,
  whitelist: ["preferences", "user", "navigation"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

// if (import.meta.env.NODE_ENV === "development" && module.hot) {
//   module.hot.accept("./reducers", () => {
//     const newRootReducer = require("./reducers").default;
//     store.replaceReducer(newRootReducer);
//   });
// }

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export default store;
