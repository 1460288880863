import {
  Button,
  DatePicker,
  Popover,
  Select,
  Stack,
  TextField,
} from "@shopify/polaris";
import {
  format,
  getMonth,
  getYear,
  isMatch,
  isSameDay,
  isValid,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subYears,
} from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";

export interface LivestreamDatePickerProps {
  selectedDates: {
    start: Date;
    end: Date;
  };
  setSelectedDates: React.Dispatch<
    React.SetStateAction<{
      start: Date;
      end: Date;
    }>
  >;
}
const dateFormat = "yyyy-MM-dd";

const dateRangeOptions = {
  Today: { start: new Date(), end: new Date() },
  Yesterday: { start: subDays(new Date(), 1), end: new Date() },
  "Last 7 days": { start: subDays(new Date(), 7), end: new Date() },
  "Last 30 days": { start: subDays(new Date(), 30), end: new Date() },
  "Last 90 days": { start: subDays(new Date(), 90), end: new Date() },
  "Last month": { start: subMonths(new Date(), 1), end: new Date() },
  "Last year": { start: subYears(new Date(), 1), end: new Date() },
  "Week to date": { start: startOfWeek(new Date()), end: new Date() },
  "Week to month": { start: startOfMonth(new Date()), end: new Date() },
  "Week to year": { start: startOfYear(new Date()), end: new Date() },
  // add quarters
};

export const LivestreamDatePicker: React.FC<LivestreamDatePickerProps> = ({
  selectedDates,
  setSelectedDates,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [{ month, year }, setDate] = useState({
    month: getMonth(subMonths(selectedDates.end, 1)),
    year: getYear(selectedDates.end),
  });

  const selectedOption = useMemo<string>(() => {
    for (const option in dateRangeOptions) {
      if (
        isSameDay(dateRangeOptions[option].start, selectedDates.start) &&
        isSameDay(dateRangeOptions[option].end, selectedDates.end)
      ) {
        return option;
      }
    }

    return `${format(selectedDates.start, dateFormat)} through ${format(
      selectedDates.end,
      dateFormat
    )}`;
  }, [selectedDates]);

  const [startDateInputValue, setStartDateInputValue] = useState<string>(
    format(selectedDates.start, dateFormat)
  );
  const [endDateInputValue, setEndDateInputValue] = useState<string>(
    format(selectedDates.end, dateFormat)
  );

  useEffect(() => {
    if (
      new Date(startDateInputValue) !== selectedDates.start &&
      isValid(new Date(startDateInputValue))
    ) {
      setStartDateInputValue(format(selectedDates.start, dateFormat));
    }
  }, [selectedDates.start, startDateInputValue]);

  useEffect(() => {
    if (
      new Date(endDateInputValue) !== selectedDates.end &&
      isValid(new Date(endDateInputValue))
    ) {
      setEndDateInputValue(format(selectedDates.end, dateFormat));
    }
  }, [selectedDates.end, endDateInputValue]);

  const handleSelectChange = useCallback(
    (value) => setSelectedDates(dateRangeOptions[value]),
    [setSelectedDates]
  );

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  );

  // changing start and end dates by input is acting funny. I may suggest to remove

  const handleStartDateChange = useCallback(
    (value: string) => {
      setStartDateInputValue(value);
      if (isMatch(value, dateFormat)) {
        setSelectedDates({ start: new Date(value), end: selectedDates.end });
      }
    },
    [setSelectedDates, selectedDates.end]
  );

  const handleEndDateChange = useCallback(
    (value: string) => {
      setEndDateInputValue(value);
      if (value.split("-").length === 3 && isMatch(value, dateFormat)) {
        setSelectedDates({ start: selectedDates.start, end: new Date(value) });
      }
    },
    [setSelectedDates, selectedDates.start]
  );

  const activator = (
    <Button onClick={() => setOpen((o) => !o)}>{selectedOption}</Button>
  );

  return (
    <Popover
      active={open}
      activator={activator}
      onClose={() => setOpen(false)}
      fluidContent
      preferredAlignment="center"
      sectioned
    >
      <Stack vertical>
        <Select
          label="Date range"
          value={selectedOption}
          onChange={handleSelectChange}
          options={Object.keys(dateRangeOptions)}
        />
        <Stack distribution="fill">
          <TextField
            label={"Start"}
            value={startDateInputValue}
            autoComplete="off"
            onChange={handleStartDateChange}
          />
          <TextField
            label={"End"}
            value={endDateInputValue}
            autoComplete="off"
            onChange={handleEndDateChange}
          />
        </Stack>
        <DatePicker
          month={month}
          year={year}
          onChange={setSelectedDates}
          onMonthChange={handleMonthChange}
          selected={selectedDates}
          disableDatesAfter={new Date()}
          allowRange
          multiMonth
        />
      </Stack>
    </Popover>
  );
};
