import React from "react";
import CartrLogo from "assets/CartrLogo.png";
import { Layout, Page } from "@shopify/polaris";
import styles from "./RedirectPage.module.css";

export const RedirectPage: React.FC = () => ( 
  <Page>
    <Layout>
      <div className={styles["wrapper"]}>
        <img src={CartrLogo} alt="Cartr Logo"></img>
        <p>Redirecting...</p>
      </div>
    </Layout>
  </Page>
);
