import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetStoreByIdResponse } from "@switcherstudio/switcher-api-client";
import { RootState } from "store/reducers";

export const getShopInfo = createAsyncThunk<
  GetStoreByIdResponse,
  string,
  {
    state: RootState,
  }>(
    "shop/getShopInfo",
    async (shop) => {
    const { client } = await import("api/client");
    const shopInfo = await client.shopifyStores_GetStoreInfoById(shop);
    return shopInfo;
    }
);
