import React from "react";
import { ConnectAccount } from "./ConnectAccount";
import { DisconnectAccount } from "./DisconnectAccount";
import { SkeletonCard } from "../SkeletonCard";

export interface AccountProps {
  onAction(): void;
  email?: string;
}

export interface AccountConnectionCardProps {
  email: string | undefined;
  connected: boolean | undefined;
  onDisconnectAction: () => void;
  onConnectAction: () => void;
}

export const AccountConnectionCard: React.FC<AccountConnectionCardProps> = ({
  email,
  connected,
  onDisconnectAction,
  onConnectAction
}) => {
  if (connected === undefined) {
    return <SkeletonCard />;
  }

  if (connected) {
    return (
      <DisconnectAccount
        onAction={onDisconnectAction}
        email={email}
      />
    );
  } else {
    return (
      <ConnectAccount onAction={() => onConnectAction()} />
    )
  }
};
