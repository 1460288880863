import React, { useCallback } from "react";
import { Button, TextContainer } from "@shopify/polaris";
import { createApp } from "@shopify/app-bridge";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { Redirect } from "@shopify/app-bridge/actions";

const dashUrl = import.meta.env.VITE_DASHBOARD_URL;

export const MerchantPlanCard = () => {
  const navigation = useSelector((s: RootState) => s.navigation);

  const toSubscriptionPage = useCallback(() => {
    const host = navigation?.host;

    if (!host) {
      return;
    }

    const app = createApp({
      apiKey: import.meta.env.VITE_SHOPIFY_CLIENTID,
      host: host,
    });

    const redirect = Redirect.create(app);

    redirect.dispatch(Redirect.Action.REMOTE, {
      url: `${dashUrl}/subscription`,
      newContext: true,
    });
  }, [navigation?.host]);

  return (
    <TextContainer>
      <h2 className="Polaris-Heading">Merchant - $99/month</h2>
      <p>
        The video power of Switcher + your Shopify store. Sell on your website or Facebook Live.
      </p>
      <Button onClick={toSubscriptionPage}>Manage Plan</Button>
    </TextContainer>
  );
};
