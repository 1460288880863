import React, { useMemo } from "react";
import { Collection } from "hooks/useGetCollections";
import { Badge, TextContainer } from "@shopify/polaris";

export interface InlineCollectionDisplayProps {
  collection: Collection;
}

export const InlineCollectionDisplay: React.FC<
  InlineCollectionDisplayProps
> = ({ collection }) => {
  const count = useMemo(() => collection.products.edges.length, [collection]);

  return (
    <TextContainer>
      <Badge status={count === 0 ? "warning" : "success"}>
        {count === 0 ? "Empty" : "Available"}
      </Badge>{" "}
      <strong>{collection.title}</strong> {count} Product
      {count !== 1 ? "s" : ""}
    </TextContainer>
  );
};
