import React from "react";
import { AccountConnection, Link, TextContainer } from "@shopify/polaris";
import { AccountProps } from ".";

export function ConnectAccount({ onAction }: AccountProps) {
  return (
    <AccountConnection
      action={{ content: 'Connect', onAction }}
      details="No account connected"
      termsOfService={
        <TextContainer>
          By clicking Connect, you are accepting Switcher's{' '}
          <Link url="https://www.switcherstudio.com/terms-of-use" external>Terms and Conditions</Link>
        </TextContainer>
      }
    />
  );
}
