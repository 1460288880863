import {
  Page,
  Layout,
  TextContainer,
  Heading,
  Card,
  Link,
  List,
  FooterHelp,
} from "@shopify/polaris";
import { AccountConnectionErrorBanner } from "components/banners/AccountConnectionErrorBanner";
import { AccountConnectionSuccessBanner } from "components/banners/AccountConnectionSuccessBanner";
import { TrialCountdownBanner } from "components/banners/TrialCountdownBanner";
import { ChoosePlanBanner } from "components/banners/ChoosePlanBanner";
import { AccountConnectionCard } from "components/cards/AccountConnectionCard";
import { CollectionsCard } from "components/cards/CollectionsCard";
import { HelpSectionCard } from "components/cards/HelpSectionCard";
import { GetStartedChecklist } from "components/checklists/GetStartedChecklist";
import { PlansSection } from "components/sections/Plans";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useGetActiveProduct } from "hooks/useGetActiveProduct";

export interface AccountsPageProps {
  connected: boolean;
  connectSwitcherAccount: (showLoginPage?: boolean) => void;
  disconnectSwitcherAccount: () => void;
  shouldShowAccountConnectionSuccessBanner: boolean;
  shouldShowAccountConnectionErrorBanner: boolean;
}

export const AccountsPage: React.FC<AccountsPageProps> = ({
  connected,
  connectSwitcherAccount,
  disconnectSwitcherAccount,
  shouldShowAccountConnectionSuccessBanner,
  shouldShowAccountConnectionErrorBanner
}) => {
  const state = useSelector((s: RootState) => s);
  const shopState = useSelector((s: RootState) => s.shop);
  const { isMerchantProduct } = useGetActiveProduct();
  const planSectionRef = useRef(null);

  return (
    <Page>
      <Layout>
        <Layout.Section>
          <TextContainer>
            <Heading element="h1">
              Getting started with Cartr and Switcher Studio
            </Heading>
            <p>
              Cartr works together with the Switcher Studio iOS app. Switcher
              lets you create stunning livestreams with iPhones and iPads, and
              Cartr integrates your Shopify products to make those streams
              shoppable!
            </p>
          </TextContainer>
        </Layout.Section>

        <Layout.Section>
          <TrialCountdownBanner
            shouldBeShownByDefault={
              shopState?.shopInfo?.trialData?.isTrialing
            }
            activeUntil={shopState?.shopInfo?.currentSubscription?.TrialEnd}
            onAction={() => {
              planSectionRef?.current?.scrollIntoView();
            }}
          />
          <ChoosePlanBanner
            shouldBeShownByDefault={
              !shopState?.shopInfo?.currentSubscription && !isMerchantProduct
            }
            onAction={() => {
              planSectionRef?.current?.scrollIntoView();
            }}
          />
          <AccountConnectionSuccessBanner
            shouldBeShownByDefault={shouldShowAccountConnectionSuccessBanner}
          />
          <AccountConnectionErrorBanner
            shouldBeShownByDefault={shouldShowAccountConnectionErrorBanner}
          />
        </Layout.Section>

        <Layout.AnnotatedSection
          title="Switcher Studio Account"
          description="Connect your Switcher Studio account to Shopify to be able to stream live shopping events on your favorite platforms!"
        >
          <AccountConnectionCard
            email={shopState?.shopInfo?.connectedShopUser?.Email}
            connected={connected}
            onDisconnectAction={disconnectSwitcherAccount}
            onConnectAction={connectSwitcherAccount}
          />
        </Layout.AnnotatedSection>

        <Layout.AnnotatedSection
          title="Get Started Checklist"
          description="You'll use Shopify to host your products, Switcher to create your streams, and Cartr to display products in those streams and finalize your customers' orders. Follow these steps to ensure Shopify, Switcher, and Cartr are all connected. "
        >
          <GetStartedChecklist />
        </Layout.AnnotatedSection>

        <PlansSection ref={planSectionRef} key={shopState?.shopInfo?.currentSubscription?.Id} />

        <CollectionsCard host={state.navigation.host} />

        <HelpSectionCard />

        <Layout.AnnotatedSection
          title="Terms and Conditions"
          description="View Switcher's Terms and Conditions and Privacy Policy here at any time."
        >
          <Card sectioned>
            <TextContainer>
              <p>
                <Link
                  url="https://www.switcherstudio.com/terms-of-use"
                  external
                >
                  Switcher's Terms and Conditions
                </Link>
              </p>
              <p>
                <Link
                  url="https://www.switcherstudio.com/privacy-policy"
                  external
                >
                  Privacy Policy
                </Link>
              </p>
            </TextContainer>
          </Card>
        </Layout.AnnotatedSection>

        <Layout.AnnotatedSection
          title="Uninstall the App"
          description="Easily uninstall the app with these simple steps."
        >
          <Card title="To uninstall Cartr, follow these steps:" sectioned>
            <List type="number">
              <List.Item>
                Click on <strong>Settings</strong> in the bottom left corner of
                this dashboard.
              </List.Item>
              <List.Item>
                Click on Sales <strong>channels.</strong>
              </List.Item>
              <List.Item>
                Find <strong>Cartr</strong> and click the{" "}
                <strong>Remove</strong> icon.
              </List.Item>
            </List>
          </Card>
        </Layout.AnnotatedSection>

        <Layout.Section>
          <FooterHelp>
            For more details on Switcher, visit the{" "}
            <Link url="https://www.switcherstudio.com">Switcher Studio</Link>{" "}
            website.
          </FooterHelp>
        </Layout.Section>
      </Layout>
    </Page>
  );
};
