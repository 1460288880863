import {
  Card,
  SkeletonBodyText,
  SkeletonDisplayText,
  TextContainer,
} from "@shopify/polaris";
import React from "react";

export const SkeletonCard: React.FC = () => (
  <Card sectioned>
    <TextContainer>
      <SkeletonDisplayText size="small" />
      <SkeletonBodyText />
    </TextContainer>
  </Card>
);
