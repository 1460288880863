import { ITicket, GetUserOrgsPayload } from "./types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "store/reducers";
import { CouponResponse, OrganizationUserDefault } from "@switcherstudio/switcher-api-client";
import { first } from "lodash";

const apiBaseUrl = import.meta.env.VITE_API_URL || "https://silversunnapi-develop.azurewebsites.net";

export const exchangeCode = createAsyncThunk(
  "user/exchangeCode",
  async (code: string) => {
    const formData = new URLSearchParams();
    formData.append("grant_type", "authorization_code");
    formData.append("client_id", import.meta.env.VITE_CLIENT_ID);
    formData.append("code", code);

    const res = await fetch(`${apiBaseUrl}/token`, {
      method: "POST",
      body: formData,
    });
    const text = await res.text();
    if (!res.ok) {
      throw new Error(text);
    }

    const ticket = JSON.parse(text) as ITicket;
    return ticket;
  }
);

export const getUserInfo = createAsyncThunk(
  "users/getUserInfo",
  async () => {
    const { client } = await import("api/client");
    const userInfo = await client.account_GetUserInfo();
    
    return userInfo;
});

export const getShopifyExternalAuth = createAsyncThunk(
  "users/getShopifyExternalAuth",
  async () => {
    const { client } = await import("api/client");
    const shopifyExternalAuth = await client.shopify_GetCredentials();
    
    return shopifyExternalAuth;
});

export const connectShopToUser = createAsyncThunk(
  "users/connectShopToUser",
  async (shop: string) => {
    const { client } = await import("api/client");
    return await client.shopify_PostConnectShopToUser(shop);
});

export const deleteConnectedShopUser = createAsyncThunk(
  "users/deleteConnectedShopUser",
  async (shop: string) => {
    const { client } = await import("api/client");
    return await client.shopify_DeleteConnectedShopUser(shop);
});

export const getUserOrgs = createAsyncThunk<
  GetUserOrgsPayload,
  void,
  {
    state: RootState
  }>(
  "users/getUserOrgs",
  async (_, { getState }) => {
    const { client } = await import("api/client");
    const state = getState();
    const orgs = await client.userOrganizations_Index(
      state.user.ticket?.userId || ""
    );
    const payload: GetUserOrgsPayload = { orgs };

    if (state.user.userInfo?.OrganizationId) {
      const org = await client.organizations_GetOrganization(
        state.user.userInfo?.OrganizationId
      );
      payload.maximumDestinations = org.MaximumDestinations || 3;
      payload.maximumUsers = org.MaximumUsers || 10;
      payload.name = org.Name || "";
    }

    return payload;
  }
);

export const refreshToken = createAsyncThunk<
  ITicket,
  void,
  {
    state: RootState
  }>(
  "users/refreshToken",
  async (_, { getState }) => {
    const tokenUrl = apiBaseUrl + "/Token";
    const state = getState();
    const formData = new URLSearchParams();

    formData.append("grant_type", "refresh_token");
    formData.append("refresh_token", state.user.ticket.refresh_token);
    formData.append("client_id", "SilverSunnDashboard");

    const tokenRes = await fetch(tokenUrl, {
      method: "POST",
      body: formData
    });

    const text = await tokenRes.text();
    if (!tokenRes.ok) {
      throw new Error(text);
    }

    const ticket = JSON.parse(text) as ITicket;
    return ticket;
  }
)

export const getDefaultUserOrg = createAsyncThunk<
  OrganizationUserDefault,
  void,
  {
    state: RootState
  }>(
  "users/getDefaultUserOrg",
  async (_, { getState }) => {
    const { client } = await import("api/client");
    const state = getState();

    const defaultOrg = await client.userOrganizations_GetDefault(state.user.userInfo.UserId);

    return defaultOrg;
  }
);

export const getUserClaimedCoupon = createAsyncThunk<
  CouponResponse,
  void,
  {
    state: RootState
  }>(
  "users/getUserClaimedCoupon",
  async (_, { getState }) => {
    const { client } = await import("api/client");
    const { shop } = getState();

    try {
      return first(
        await client.shopifyCoupons_GetClaimedCouponCodesByShop(
          shop.shopInfo.shopId
        )
      ) ?? null;
    } catch (e) {
      return null;
    }
  }
);

export const claimCoupon = createAsyncThunk<
  CouponResponse | null,
  string,
  {
    state: RootState;
  }
>("users/claimCoupon", async (code: string, { getState }) => {
  const { client } = await import("api/client");
  const { shop } = getState();

  if (shop.shopInfo) {
    try {
      const claimedCoupon = await client.shopifyCoupons_ClaimCouponCode(
        shop.shopInfo.shopId,
        code
      );

      return claimedCoupon;
    } catch {
      return null;
    }
  }
});

export const removeClaimedCoupon = createAsyncThunk<
  CouponResponse | null,
  void,
  {
    state: RootState;
  }
>("users/removeClaimedCoupon", async (_, { getState }) => {
  const { client } = await import("api/client");
  const {
    shop,
    user: { claimedCoupon },
  } = getState();

  if (claimedCoupon) {
    try {
      await client.shopifyCoupons_ReleaseCouponCode(
        shop.shopInfo.shopId,
        claimedCoupon.ResellerInventoryItem.Id
      );

      return null;
    } catch {
      return claimedCoupon;
    }
  }
});
