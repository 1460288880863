import React from "react";
import { PlanState } from "./types";
import { CouponResponse, Product } from "@switcherstudio/switcher-api-client";
import { useGetAvailableShopifyPlans } from "hooks/useGetAvailableShopifyPlans";
import { SkeletonPlanCard } from "./SkeletonPlanCard";
import { ShopifyPlanCard } from "./ShopifyPlanCard";
import { MerchantPlanCard } from "./MerchantPlanCard";

export const PlansCardContent = ({
  currentUserProduct,
  planState,
  claimedCoupon,
}: {
  currentUserProduct: Product;
  planState: PlanState;
  claimedCoupon: CouponResponse;
}) => {
  const { loading, shopifyPlans } = useGetAvailableShopifyPlans();

  if (planState === PlanState.LOADING || loading) {
    return <SkeletonPlanCard />;
  }

  if (planState === PlanState.STRIPE_MERCHANT_PLAN) {
    return <MerchantPlanCard />
  }

  return (
    <ShopifyPlanCard
      shopifyPlans={shopifyPlans}
      currentUserProduct={currentUserProduct}
      planState={planState}
      claimedCoupon={claimedCoupon}
    />
  );
};
