import { Product, ShopifySubscription } from "@switcherstudio/switcher-api-client"
import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";

export const useGetActiveProduct = () => {
    const [activeProduct, setActiveProduct] = useState<Product>();
    const [isMerchantProduct, setIsMerchantProduct] = useState<boolean>(false);
    const [shopifySubscription, setShopifySubscription] = useState<ShopifySubscription>();
    const shop = useSelector((s: RootState) => s.shop);
    const subscriptionFromShop = useMemo(() => shop?.shopInfo?.currentSubscription, [shop]);
    const userProduct = useMemo(() => shop?.shopInfo?.connectedShopUser?.ActiveProduct, [shop]);
    useEffect(() => {
        if (subscriptionFromShop) {
            setShopifySubscription(subscriptionFromShop);
            setActiveProduct(subscriptionFromShop.ShopifyProduct);
            setIsMerchantProduct(true);
        } else if (shop?.shopInfo?.connectedShopUser) {
            setActiveProduct(userProduct);

            if(shop?.shopInfo?.connectedShopUser?.Roles.includes("Merchant")){
                setIsMerchantProduct(true);
            }
        } else {
            setIsMerchantProduct(false);
        }

    }, [shop, subscriptionFromShop, userProduct]);

    // activeProduct should always be truthy but shopifySubscription can be null
    return { activeProduct, shopifySubscription, isMerchantProduct };
}
