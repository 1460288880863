import React, { useEffect, useState } from "react";
import { Banner } from "@shopify/polaris";

export interface AccountConnectionSuccessBannerProps {
  shouldBeShownByDefault: Boolean;
}

export const AccountConnectionSuccessBanner: React.FC<AccountConnectionSuccessBannerProps> = ({
  shouldBeShownByDefault
}) => {
  const [dismissed, setDismissed] = useState<Boolean>(!shouldBeShownByDefault);

  useEffect(() => {
    setDismissed(!shouldBeShownByDefault);
  }, [shouldBeShownByDefault])

  if (dismissed) {
    return null;
  }

  return (
    <Banner
      title="Nice, You've been approved to sell with Cartr"
      status="success"
      onDismiss={() => setDismissed(true)}
    >
      <p>
        Continue setup to begin selling with Cartr.
      </p>
    </Banner>
  );
};
