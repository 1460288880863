import React, { useCallback } from "react";
import { Banner, Link } from "@shopify/polaris";
import { CouponResponse } from "@switcherstudio/switcher-api-client";
import { useDispatch } from "react-redux";
import { removeClaimedCoupon } from "store/user/thunks";

export interface ClaimedCouponBannerProps {
  claimedCoupon: CouponResponse;
}

export const ClaimedCouponBanner: React.FC<ClaimedCouponBannerProps> = ({
  claimedCoupon,
}) => {
  const dispatch = useDispatch();

  const remove = useCallback(() => {
    dispatch(removeClaimedCoupon())
  }, [dispatch]);

  return (
    <Banner
      title={`${claimedCoupon.ResellerInventoryItem.CouponCode} applied`}
      status="success"
    >
      <p>
        {claimedCoupon.ResellerInventoryItem.ResellerInventory.Description}
        {" "}
        <Link onClick={remove}>Remove Coupon Code</Link>
      </p>
    </Banner>
  );
};
