import React, { useCallback, useState } from "react";
import { CouponResponse } from "@switcherstudio/switcher-api-client";
import {
  Card,
  Layout,
  Link,
  Spinner,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";
import { ClaimedCouponBanner } from "components/banners/ClaimedCouponBanner";
import { CouponInputField } from "components/inputs/CouponInputField";

export interface CouponInputSectionProps {
  claimedCoupon: CouponResponse;
  isPrompted?: boolean;
  isSubsection?: boolean;
}

export const CouponInputSection: React.FC<CouponInputSectionProps> = ({
  claimedCoupon,
  isPrompted,
  isSubsection,
}) => {
  const [showPrompt, setShowPrompt] = useState<boolean>(isPrompted);

  const body = useCallback(() => {
    if (claimedCoupon === undefined) {
      return <Spinner />;
    }
    
    if (showPrompt && claimedCoupon === null) {
      return (
        <TextContainer>
          <TextStyle variation="subdued">Have a coupon code?</TextStyle>{" "}
          <Link onClick={() => setShowPrompt(false)}>Redeem it now</Link>
        </TextContainer>
      );
    }

    return (
      <>
        {claimedCoupon !== null ? (
          <ClaimedCouponBanner claimedCoupon={claimedCoupon} />
        ) : (
          <CouponInputField />
        )}
      </>
    );
  }, [claimedCoupon, showPrompt]);

  if (isSubsection) {
    return <Card.Subsection>{body()}</Card.Subsection>;
  } else {
    return <Layout.Section>{body()}</Layout.Section>;
  }
};
