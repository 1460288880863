import React, { useEffect, useState } from "react";
import { Banner } from "@shopify/polaris";
import { useGetDayCount } from "hooks/useGetDayCount";

export interface TrialCountdownBannerProps {
  shouldBeShownByDefault: Boolean;
  activeUntil: string;
  onAction?: () => void
}

export const TrialCountdownBanner: React.FC<TrialCountdownBannerProps> = ({
  shouldBeShownByDefault,
  activeUntil,
  onAction
}) => {
  const [dismissed, setDismissed] = useState<Boolean>(!shouldBeShownByDefault);
  const count = useGetDayCount(activeUntil);

  useEffect(() => {
    setDismissed(!shouldBeShownByDefault);
  }, [shouldBeShownByDefault])

  if (dismissed) {
    return null;
  }

  var bannerAction = {
    content: "View Subscriptions",
    onAction: onAction
  };

  return (
    <Banner
      title={`Your Switcher Studio free trial expires in ${count} day${count !== 1 ? "s" : ""}.`}
      action={bannerAction}
      status="info"
      onDismiss={() => setDismissed(true)}
    >
      <p>
        Subscribe to Switcher in order to create shoppable livestreams on
        Facebook.
      </p>
    </Banner>
  );
};
