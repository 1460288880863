import React from "react";
import { Layout, Page, Heading, Loading, Frame } from "@shopify/polaris";
import { CardStack } from "components/stacks/CardStack";
import { useAllTimeHistory } from "hooks/useAllTimeHistory";
import { LivestreamsTable } from "./LivestreamsTable";
import store from "store/store";
import { format } from "helpers/currency";

export interface AnalyticPageProps {}

export const AnalyticsPage: React.FC<AnalyticPageProps> = () => {
  const state = store.getState();
  const {shop, locale} = state.navigation;

  const {loading, allTimeHistory} = useAllTimeHistory(shop);

  if (loading) {
    return <Frame><Loading /></Frame>
  }

  return (
    <Page
      title="All-time history"
      // primaryAction={
      //   <Button
      //     monochrome
      //     connectedDisclosure={{
      //       accessibilityLabel: "Other actions",
      //       actions: [
      //         {
      //           content: "Another action",
      //           accessibilityLabel: "Another action",
      //           onAction: () => console.log("Another action"),
      //         },
      //       ],
      //     }}
      //   >
      //     More Actions
      //   </Button>
      // }
      // secondaryActions={[
      //   {
      //     content: "Print",
      //     accessibilityLabel: "Print analytics",
      //     onAction: () => console.log("Print"),
      //   },
      //   {
      //     content: "Export",
      //     accessibilityLabel: "Export analytics",
      //     onAction: () => console.log("Export"),
      //   },
      // ]}
    >
      <Layout>
        <div style={{width: "100%"}}>
          <Layout.Section>
            <CardStack
              cards={[
                {
                  title: "Unique Buyers",
                  content: allTimeHistory.UniqueBuyers,
                },
                {
                  title: "Items sold",
                  content: allTimeHistory.TotalItemsSold,
                },
                {
                  title: "Amount sold",
                  content: format(allTimeHistory.Currency, allTimeHistory.TotalSales, locale),
                },
              ]}
            />
          </Layout.Section>

          <Layout.Section>
            <Heading>Your livestreams</Heading>
            <LivestreamsTable shopId={shop} locale={locale} />
          </Layout.Section>
        </div>
      </Layout>
    </Page>
  );
};
