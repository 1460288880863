import React from "react";
import {
  Card,
  TextContainer,
  TextStyle,
} from "@shopify/polaris";
import { ToDoItem } from "components/checklists/GetStartedChecklist/types";
import styles from './GetStartedChecklist.module.css';

export const GetStartedChecklistItem = ({
  toDo: { title, icon, description, list, link },
}: {
  toDo: ToDoItem;
}) => (
  <Card.Subsection>
    <div className={styles["container"]}>
    {icon}
      <div className={styles["text-container"]}>
        <TextContainer spacing="tight">
          <TextStyle variation="strong">{title}</TextStyle>
          {description}
          {list && <>{list}</>}
          {link && <>{link}</>}
        </TextContainer>
      </div>
    </div>
  </Card.Subsection>
);
