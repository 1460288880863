import { SortDirection } from "@shopify/polaris";
import { sortCurrency, sortNumber, sortWord } from "helpers/tables";
import { useCallback, useState } from "react";

const livestreamData = {
  Id: "09d2a137-a3f8-440b-8ffc-be802e6d433a",
  Name: "Live name",
  Date: "DD.MM.YYYY",
  UniqueBuyers: 12,
  ItemsSold: 124689,
  AmountSold: "$456",
  SalesFee: "3%",
  ProductsSold: [
    {
      Id: "5d5d8517-e7ba-4384-80dd-d8df8d5ebaeb",
      Name: "Product name #1",
      Upi: "gsd-123a",
      ItemsSold: 10,
      Amount: "$1000",
    },
    {
      Id: "b64ecab7-65fc-41a0-a9d6-d4cf54b7d70d",
      Name: "Product name #2",
      Upi: "124a",
      ItemsSold: 20,
      Amount: "$2000",
    },
    {
      Id: "70e1953c-afea-43dc-b9f3-8ced91e0ccaf",
      Name: "Product name #3",
      Upi: "125a",
      ItemsSold: 30,
      Amount: "$3000",
    },
    {
      Id: "99e4b4ee-b0de-435a-a697-88cb4dec74c8",
      Name: "Product name #4",
      Upi: "126a",
      ItemsSold: 23,
      Amount: "$4000",
    },
  ],
};

const transactionColumns = ["Name", "Upi", "ItemsSold", "Amount"];

export const useGetLivestream = (id: string) => {
  const [livestream, setLivestream] = useState(livestreamData);

  const handleSortProductsSold = useCallback(
    (headingIndex: number, direction: SortDirection) => {
      setLivestream((livestream) => {
      switch (transactionColumns[headingIndex]) {
        case "Name":
          return {
            ...livestream,
            ProductsSold: sortWord(
              livestream.ProductsSold,
              transactionColumns[headingIndex],
              direction
            ),
          };
        case "ItemsSold":
            return {
              ...livestream,
              ProductsSold: sortNumber(
                livestream.ProductsSold,
                transactionColumns[headingIndex],
                direction
              ),
            };
        case "Amount":
            return {
              ...livestream,
              ProductsSold: sortCurrency(
                livestream.ProductsSold,
                transactionColumns[headingIndex],
                direction
              ),
            };
          }
        });
    },
    []
  );

  return { livestream, handleSortProductsSold };
};
