import { client } from "api/client"
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { getShopInfo } from "store/shop/thunks";

export const useDeleteAppSubscription = () => {
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const navigationState = useSelector((s: RootState) => s.navigation);
    const shop = useMemo(() => navigationState?.shop, [navigationState]);
    const shopState = useSelector((s: RootState) => s.shop);
    const subscriptionId = useMemo(() => shopState?.shopInfo?.currentSubscription?.Id, [shopState]);
    const dispatch = useDispatch();


    const deleteSubscription = useCallback(async () => {
        try {
            setLoading(true);
            await client.shopifySubscriptions_DeleteShopifySubscription(shop, subscriptionId);
            dispatch(getShopInfo(shop));
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setError(e);
        }
    }, [shop, subscriptionId, dispatch]);
    return { deleteSubscription, error, loading }
}