import React from "react";
import { Card } from "@shopify/polaris";
import { PlanState } from "./types";
import { CouponResponse, Product } from "@switcherstudio/switcher-api-client";

import { PlansCardContent } from "./PlansCardContent";

export const PlansCard = ({
  currentUserProduct,
  planState,
  claimedCoupon,
}: {
  currentUserProduct: Product;
  planState: PlanState;
  claimedCoupon: CouponResponse;
}) => (
  <Card sectioned>
    <PlansCardContent
      claimedCoupon={claimedCoupon}
      currentUserProduct={currentUserProduct}
      planState={planState}
    />
  </Card>
);
