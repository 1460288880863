import { Modal, TextContainer } from "@shopify/polaris";
import React from "react";

export interface DisconnectModalProps {
  open: boolean;
  cancel: () => void;
  onAction: () => void;
}

export const DisconnectModal: React.FC<DisconnectModalProps> = ({
  open,
  onAction,
  cancel,
}) => (
  <Modal
    open={open}
    onClose={cancel}
    title="Disconnect you Switcher account?"
    primaryAction={{
      content: "Disconnect",
      onAction,
    }}
    secondaryActions={[
      {
        content: "Cancel",
        onAction: cancel,
      },
    ]}
  >
    <Modal.Section>
      <TextContainer>
        <p>
          Your products will no longer be accessible within Switcher Studio.
        </p>
      </TextContainer>
    </Modal.Section>
  </Modal>
);
