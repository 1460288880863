import { useMemo } from "react";
import { useShopQuery } from "./useShopQuery";

const COLLECTIONS = `{
  collections(first: 100) {
    edges {
      node {
        id
        title
        products(first: 100) {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
}`;

export interface Collection {
  id: string;
  handle: string;
  title: string;
  products: any;
}

export const useGetCollections = (): {
  collections: Collection[];
  productCount: number;
  error: any;
  loading: boolean;
} => {
  const { loading, error, data } = useShopQuery(COLLECTIONS);

  const uniqueProducts = useMemo(
    () =>
      new Set(
        data?.collections?.edges
          .map((c: any) => c.node.products.edges)
          .flat()
          .map((e: any) => e.node.id)
      ),
    [data]
  );

  return {
    collections: data?.collections?.edges.map((c: any) => c.node),
    productCount: uniqueProducts.size,
    error,
    loading,
  };
};
