import { ShopifyPrice, ShopifyProductDetails } from "@switcherstudio/switcher-api-client";

export interface Plan {
    id: string;
    name: string;
    title: string;
    price: number;
    formattedPrice: string;
    description: string;
    features: string[];
    salesFee: string;
    prices: ShopifyPrice[];
}

export const formatSubscriptionPlans = (products: ShopifyProductDetails[]): Plan[] => !!products && products.map((product): Plan => {
    return {
        id: product.id,
        name: product.name,
        title: product?.pricingDetails?.revenueSharePercentage
            ? `${product.name} - $${product?.pricingDetails?.amount}/month + ${product?.pricingDetails?.revenueSharePercentage}% of sales`
            : `${product.name} - $${product?.pricingDetails?.amount}/month`,
        price: product?.pricingDetails?.amount,
        formattedPrice: `$${product?.pricingDetails?.amount}`,
        description: product.description,
        features: product.features,
        salesFee: product?.pricingDetails?.revenueSharePercentage
            ? `${product?.pricingDetails?.revenueSharePercentage}%`
            : "",
        prices: product?.prices,
    }
});
