import { combineReducers } from "@reduxjs/toolkit";
import app from "./app/slice";
import user from "./user/slice";
import rollbar from "./rollbar/slice";
import navigation from "./navigation/slice";
import shop from "./shop/slice";
const rootReducer = combineReducers({
  app,
  user,
  rollbar,
  navigation,
  shop,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
