import { useEffect, useState } from "react";
import { client } from "api/client";
import { StoreAnalyticsAllTimeSummary } from "@switcherstudio/switcher-api-client";

export const useAllTimeHistory = (shopId: string) => {
  const [allTimeHistory, setOut] = useState<StoreAnalyticsAllTimeSummary>()

  useEffect(() => {
    const fetch = async () => {
      setOut(await client.shopify_GetShopifyAnalyticsByShopId(shopId))
    }

    fetch();
  }, [shopId])

  return {loading: !allTimeHistory, allTimeHistory}
}