import { createSlice, PayloadAction, Reducer } from "@reduxjs/toolkit";
import { UserState, ITicket, IUserInfo, GetUserOrgsPayload, AppSubscriptionCreateResponse } from "./types";
import {
  getUserInfo,
  getUserOrgs,
  refreshToken,
  getDefaultUserOrg,
  exchangeCode,
  getShopifyExternalAuth,
  connectShopToUser,
  claimCoupon,
  removeClaimedCoupon,
  getUserClaimedCoupon,
} from "./thunks";
import { CouponResponse, OrganizationUserDefault } from "@switcherstudio/switcher-api-client";

// TODO: configure these somewhere (store?)

const initialState = {
  ticket: undefined,
  userInfo: undefined,
  userStreamDestinations: [],
  orgStreamDestinations: [],
  orgMaxStreamDestinations: undefined,
  orgMaxUsers: undefined,
  defaultStreamDestination: undefined,
  orgName: undefined,
  orgs: [],
  userFeatures: [],
  defaultOrg: undefined,
  projectId: undefined,
  shopifyExternalAuth: undefined,
  createdAppSubscription: undefined,
  claimedCoupon: undefined
} as UserState;

export const user = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    logout: () => initialState,
    identify: (state) => {
      analytics.identify(state.userInfo?.UserId || '',
        {
          email: state.userInfo?.Email || '',
          username: state.userInfo?.Email || '',
          // avatar: getGravatarImageUrl(),
          plan: state.ticket?.activePlan || '',
          enterprise: state.userInfo?.OrganizationId ? true : false,
        });
    },
    setTicket: (state, { payload }: PayloadAction<ITicket>) => {
      state.ticket = payload
    },
    setCreatedAppSubscription: (state, { payload }: PayloadAction<AppSubscriptionCreateResponse>) => {
      state.createdAppSubscription = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(exchangeCode.fulfilled, (state, { payload }: PayloadAction<ITicket>) => {
      state.ticket = payload;
    });

    builder.addCase(getUserInfo.fulfilled, (state, { payload }: PayloadAction<IUserInfo>) => {
      state.userInfo = payload;
    });

    builder.addCase(getUserOrgs.fulfilled, (state, { payload }: PayloadAction<GetUserOrgsPayload>) => {
      const { orgs, maximumDestinations, maximumUsers, name } = payload;
      state.orgs = orgs;
      state.orgMaxStreamDestinations = maximumDestinations;
      state.orgMaxUsers = maximumUsers;
      state.orgName = name;
    });

    builder.addCase(refreshToken.fulfilled, (state, { payload }: PayloadAction<ITicket>) => {
      state.ticket = payload
    });

    builder.addCase(getDefaultUserOrg.fulfilled, (state, { payload }: PayloadAction<OrganizationUserDefault>) => {
      state.defaultOrg = payload
    });

    builder.addCase(getUserClaimedCoupon.fulfilled, (state, { payload }: PayloadAction<CouponResponse>) => {
      state.claimedCoupon = payload
    })

    builder.addCase(claimCoupon.fulfilled, (state, { payload }: PayloadAction<CouponResponse>) => {
      state.claimedCoupon = payload
    })

    builder.addCase(removeClaimedCoupon.fulfilled, (state, { payload }: PayloadAction<CouponResponse>) => {
      state.claimedCoupon = payload
    })

    builder.addCase(exchangeCode.rejected, (_, { error }) => {
      throw error;
    });

    builder.addCase(getUserInfo.rejected, (_, { error }) => {
      throw error;
    });

    builder.addCase(getShopifyExternalAuth.rejected, (_, { error }) => {
      throw error;
    });

    builder.addCase(connectShopToUser.rejected, (_, { error }) => {
      throw error;
    });

    builder.addCase(getUserOrgs.rejected, (_, { error }) => {
      throw error;
    });

    builder.addCase(refreshToken.rejected, (_, { error }) => {
      throw error;
    });

    builder.addCase(getDefaultUserOrg.rejected, (_, { error }) => {
      throw error;
    });

    builder.addCase(getUserClaimedCoupon.rejected, (_, { error }) => {
      throw error;
    });

    builder.addCase(claimCoupon.rejected, (_, { error }) => {
      throw error;
    });

    builder.addCase(removeClaimedCoupon.rejected, (_, { error }) => {
      throw error;
    });
  }
});

export const {
  logout,
  identify,
  setTicket,
  setCreatedAppSubscription,
} = user.actions;

export default user.reducer as Reducer<UserState>;
