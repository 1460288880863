import React from 'react';
import styles from './index.module.css';
import { Button, ButtonGroup } from '@shopify/polaris';
import { Plan } from 'helpers/subscriptionPlans';
import { CouponResponse, Product } from '@switcherstudio/switcher-api-client';
import { PlanState } from './types';
import { useCreateAppSubscription } from 'hooks/useCreateAppSubscription';
import { useDeleteAppSubscription } from 'hooks/useDeleteAppSubscription';

export const PlanButtonGroup = ({
    plan,
    activeProduct,
    onExpandFeatures,
    featuresExpanded,
    planState,
    claimedCoupon
}: {
    plan: Plan,
    activeProduct: Product,
    onExpandFeatures: (bool: boolean) => void;
    featuresExpanded: boolean;
    planState,
    claimedCoupon: CouponResponse
}) => {
    const {
        createAppSubscription,
        loading: createSubscriptionLoading,
    } = useCreateAppSubscription(claimedCoupon);
    const {
        deleteSubscription,
        loading: deleteSubscriptionLoading,
    } = useDeleteAppSubscription();
    return (
            <div className={styles["plan-buttons-container"]}>
                    {planState === PlanState.SHOPIFY_SUBSCRIPTION
                    ? (
                        <ButtonGroup>
                            <Button
                                onClick={deleteSubscription}
                                destructive
                                loading={deleteSubscriptionLoading}
                            >
                               Cancel Plan 
                            </Button>
                            {/* TO-DO: allow users to change plans */}
                            {/* <Button
                                primary
                            >
                                Choose Plan
                            </Button> */}
                        </ButtonGroup>
                    )
                    : (
                        <ButtonGroup>
                            <Button
                                onClick={() => onExpandFeatures(!featuresExpanded)}
                            >
                                {featuresExpanded ? "Hide Features" : "View Features"}
                            </Button>
                            <Button
                                primary
                                onClick={() => {
                                    createAppSubscription(plan)
                                }}
                                loading={createSubscriptionLoading}
                            >
                                Choose Plan
                            </Button>
                        </ButtonGroup>
                    )}
            </div>
    );
};