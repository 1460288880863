import { SortDirection } from "@shopify/polaris";
import { BroadcastProduct } from "pages/LivestreamDetailPage.tsx/ProductsSoldTable";

export const calculateTotals = (rows: any[][], disabledIndexes: number[] = []) => {
  if (!rows || rows.length === 0) return;

  const rotated = rows[0].map((_, i) => rows.map((row) => row[i]));
  const totals = [];
  let prefix = "";

  for (let i = 0; i < rotated.length; i++) {
    let total = 0;

    for (let j = 0; j < rotated[i].length; j++) {
      if (!isNaN(parseInt(rotated[i][j]))) {
        total += parseInt(rotated[i][j]);
      } else {
        if (typeof rotated[i][j] !== "string") {
          total = NaN;
          continue;
        }
        if (!isNaN(parseFloat(rotated[i][j].slice(1)))) {
          prefix = rotated[i][j].slice(0, 1);
          total += parseFloat(rotated[i][j].slice(1));
        } else {
          total = NaN;
        }
      }
    }

    totals.push(prefix + total);
  }

  const output = totals.map((total) => (total !== "" + NaN ? total : ""))

  for (const index of disabledIndexes) {
    output[index] = '';
  }

  return output;
};

export const sortCurrency = (rows: BroadcastProduct[], prop: string, direction: SortDirection) => {
  return [...rows].sort((rowA, rowB) => {
    const a = parseFloat(rowA[prop].substring(1));
    const b = parseFloat(rowB[prop].substring(1));

    return direction === 'descending' ? b - a : a - b;
  });
}

export const sortNumber = (rows: BroadcastProduct[], prop: string, direction: SortDirection) => {
  return [...rows].sort((rowA, rowB) => {
    const a = parseFloat(rowA[prop]);
    const b = parseFloat(rowB[prop]);

    return direction === 'descending' ? b - a : a - b;
  });
}

export const sortWord = (rows: BroadcastProduct[], prop: string, direction: SortDirection) => {
  return [...rows].sort((rowA, rowB) => {
    const a = rowA[prop];
    const b = rowB[prop];

    return direction === 'descending' ? b.localeCompare(a) : a.localeCompare(b);
  });
}