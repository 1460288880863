import { client } from "api/client";
import { formatSubscriptionPlans, Plan } from "helpers/subscriptionPlans";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";

export const useGetAvailableShopifyPlans = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<any>();
    const [shopifyPlans, setShopifyPlans] = useState<Plan[]>();
    const shopId = useSelector((s: RootState) => s.navigation?.shop);

    useEffect(() => {
        const fetch = async () => {
            try {
                const response = await client.shopifyProducts_GetShopifyProducts(shopId);
                setShopifyPlans(formatSubscriptionPlans(response?.products));
            } catch (e) {
                setError(e);
            }
            setLoading(false);
        }
        fetch();
    }, [shopId]);

    return {
        loading,
        error,
        shopifyPlans
    }
}
